import React, { useEffect, useState } from "react";
import "./QueueProcessing.scss";
import { Spin, Typography } from "antd";
import { useSocket } from "../../../../common/services/useSocket";
import { CustomEmpty } from "../../../../components";
import classNames from "classnames";

export default function QueueProcessing({ token, role }) {
  const [animate, setAnimate] = useState(false);
  const [selectedPatint, setSelectedPatint] = useState(null);
  const [ready, val] = useSocket();
  const [queue, set_queue] = useState([]);
  const [queue_loading, set_queue_loading] = useState(false);

  function playSound() {
    var audio = new Audio("/assets/update_queue.mp3");
    let promise = audio?.play();
    promise?.catch((error) => {});
  }

  useEffect(() => {
    // Set animation colors dynamically from token
    if (token?.colorPrimary) {
      document?.documentElement?.style?.setProperty(
        "--primary-color",
        token?.colorPrimary
      );
    }
  }, [token]);

  useEffect(() => {
    if (val) {
      const body = JSON.parse(val);
      if (body && body?.patient && body?.message && role?.is_queue_viewer) {
        set_queue_loading(true);
        setSelectedPatint(body?.patient);
        const newPatient = {
          patient: body?.patient,
          message: body?.message,
        };

        // Extract desk_number from message
        const newDeskNumber = body?.message?.desk_number;

        // Add new patient at the beginning of the queue
        set_queue((prevQueue) => {
          // Filter out old patients with the same desk_number
          const filteredQueue = prevQueue?.filter(
            (item) =>
              item?.message?.desk_number?.toString() !==
              newDeskNumber?.toString()
          );

          // Add new patient at the beginning of the queue
          return [newPatient, ...filteredQueue];
        });
        set_queue_loading(false);
      }
    }
  }, [ready, val, role]);

  useEffect(() => {
    if (selectedPatint) {
      setAnimate(true);
      const timer = setTimeout(() => {
        setAnimate(false);
        setSelectedPatint(null);
      }, 4000);
      playSound();
      return () => clearTimeout(timer);
    }
  }, [selectedPatint]);

  return (
    <div className="queueProcessing-cont">
      <Typography.Text
        style={{
          color: token?.Typography?.mainColorText,
          fontSize: "24px",
          fontWeight: "600",
        }}>
        Processing
      </Typography.Text>
      <Spin spinning={queue_loading}>
        {queue?.length ? (
          <div className="queueProcessing-cont__cards">
            {queue?.map((queue) => (
              <div
                key={queue?.patient?.id}
                className={classNames("queueProcessing-cont__cards__card", {
                  "queueProcessing-cont__highlight":
                    animate &&
                    selectedPatint?.id?.toString() ===
                      queue?.patient?.id?.toString(),
                })}
                style={{
                  background: token?.Card?.cardTicketGradient,
                }}>
                <Typography.Text
                  className="queueProcessing-cont__ticketNumber"
                  style={{
                    color: token?.Typography?.mainColorText,
                  }}>
                  Ticket Number
                </Typography.Text>
                <Typography.Text
                  className="queueProcessing-cont__number"
                  style={{
                    color: token?.colorPrimary,
                  }}>
                  {queue?.message?.code ? queue?.message?.code : ""}
                </Typography.Text>
                <div className="queueProcessing-cont__cards__card__paymentDesk">
                  <Typography.Text
                    className="queueProcessing-cont__ticketNumber"
                    style={{
                      color: token?.Typography?.mainColorText,
                    }}>
                    Please Procced To Payment Desk
                  </Typography.Text>
                  <Typography.Text
                    className="queueProcessing-cont__number"
                    style={{
                      color: token?.colorPrimary,
                    }}>
                    {queue?.message?.desk_number
                      ? queue?.message?.desk_number
                      : ""}
                  </Typography.Text>
                  <div></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <CustomEmpty description="No Queue" />
        )}
      </Spin>
    </div>
  );
}
