import React, { useMemo, useState } from "react";
import "./Cashier.scss";
import { Content } from "antd/es/layout/layout";
import useProducts from "../../hooks/useProducts";
import {
  PromoCodesSlider,
  CustomEmpty,
  ProductCard,
  ProductDrawer,
} from "../../components";
import { Typography, Dropdown, Button, Spin, Pagination } from "antd";
import { DownOutlined, FunnelPlotOutlined } from "@ant-design/icons";
import { Filters, CreateOrder } from "./components";
import { invertTheme } from "../../config/theme";
import classNames from "classnames";
import { DeskNumber } from "../../modals";
import LocalStorageService from "../../common/services/LocalStorageService";
import Utils from "../../utils";

export default function Cashier() {
  const {
    contextHolder,
    token,
    isAuthenticated,
    meta,
    loading,
    arrangeOptions,
    setUrlSearchParams,
    searchParams,
    isFilterOpened,
    setIsFilterOpened,
    categories,
    catgeoriesLoading,
    onCategoryChange,
    brands,
    brandsLoading,
    onBrandChange,
    onGeneticsChange,
    priceRange,
    onPriceRangeChange,
    onCheckboxChange,
    maxPrice,
    onSearchInpChange,
    searchVal,
    isMobile,
    productContRef,
    products,
    navigate,
    openDrawer,
    handlePagination,
    width,
    setIsDrawerOpened,
    isDrawerOpened,
    setSelectedProduct,
    selectedProduct,
    openNotificationWithIcon,
  } = useProducts();
  const is_phone_size = width <= 767;

  const has_cashier_entered_desk_number = useMemo(() => {
    return LocalStorageService?.get("has_cashier_entered_desk_number") &&
      isAuthenticated
      ? true
      : false;
  }, [isAuthenticated]);

  const [is_desk_number_modal_opened, set_is_desk_number_modal_opened] =
    useState(has_cashier_entered_desk_number ? false : true);

  return (
    <>
      {contextHolder}
      <Content
        className="cashier-cont"
        style={{
          backgroundColor: token?.Layout?.contentBg,
        }}>
        <PromoCodesSlider isAuthenticated={isAuthenticated} token={token} />
        <div className="cashier-cont__globalFilters">
          <div className="cashier-cont__globalFilters__productsCountWrapper">
            <div className="cashier-cont__globalFilters__productsCountWrapper__countCont">
              <div className="cashier-cont__globalFilters__productsCountWrapper__textsWrapper">
                <Typography.Text
                  className="cashier-cont__globalFilters__productsCountWrapper__text"
                  style={{
                    color: token?.Typography?.mainColorText,
                  }}>
                  All Products
                </Typography.Text>
                <Typography.Text
                  className="cashier-cont__globalFilters__productsCountWrapper__text"
                  style={{
                    color: token?.Typography?.colorPrimaryText,
                  }}>
                  {meta?.count ? meta?.count : 0}
                </Typography.Text>
                <Typography.Text
                  className="cashier-cont__globalFilters__productsCountWrapper__text"
                  style={{
                    color: token?.Typography?.mainColorText,
                  }}>
                  results
                </Typography.Text>
              </div>
              <Dropdown
                className="cashier-cont__globalFilters__productsCountWrapper__countCont__dropDown"
                trigger={["click"]}
                disabled={loading}
                menu={{
                  items: arrangeOptions,
                  onClick: ({ key }) => {
                    if (key === "ascending") {
                      setUrlSearchParams({
                        page: 1,
                        per_page: 25,
                        order_by: "price",
                      });
                    } else if (key === "descending") {
                      setUrlSearchParams({
                        page: 1,
                        per_page: 25,
                        order_by: "-price",
                      });
                    } else {
                      setUrlSearchParams({
                        page: 1,
                        per_page: 25,
                        order_by: undefined,
                      });
                    }
                  },
                }}>
                <div className="cashier-cont__globalFilters__productsCountWrapper__countCont__dropDown__content">
                  <Typography.Text
                    className="cashier-cont__globalFilters__productsCountWrapper__countCont__dropDown__content__text"
                    style={{
                      color: token?.Typography?.mainColorText,
                    }}>
                    {searchParams.get("order_by") === "price"
                      ? "Ascending"
                      : searchParams.get("order_by") === "-price"
                      ? "Descending"
                      : "Sort By"}
                  </Typography.Text>
                  <DownOutlined
                    style={{
                      fontSize: "14px",
                      color: token?.icons?.whiteIcon,
                    }}
                  />
                </div>
              </Dropdown>
            </div>
            <Button
              type={isFilterOpened ? "primary" : ""}
              icon={
                <FunnelPlotOutlined
                  style={{
                    color: isFilterOpened
                      ? token?.icons?.bgColorIcon
                      : token?.icons?.whiteIcon,
                    fontSize: "16px",
                  }}
                />
              }
              size="small"
              onClick={() => {
                setIsFilterOpened(!isFilterOpened);
              }}
            />
          </div>
          <Filters
            token={token}
            invertTheme={invertTheme}
            isFilterOpened={isFilterOpened}
            loading={loading}
            searchVal={searchVal}
            onSearchInpChange={onSearchInpChange}
            categories={categories}
            catgeoriesLoading={catgeoriesLoading}
            selectedCategories={searchParams?.get("category")?.split(",") || []}
            onCategoryChange={onCategoryChange}
            filterOption={Utils?.filterOption}
            brands={brands}
            brandsLoading={brandsLoading}
            selectedBrands={searchParams?.get("brand_ids")?.split(",") || []}
            onBrandChange={onBrandChange}
            selectedGenetics={searchParams?.get("genetics")?.split(",") || []}
            onGeneticsChange={onGeneticsChange}
            priceRange={priceRange}
            onPriceRangeChange={onPriceRangeChange}
            isOnsale={Boolean(searchParams?.get("sale")) || false}
            onCheckboxChange={onCheckboxChange}
            maxPrice={maxPrice}
          />
        </div>
        <div className="cashier-cont__products">
          {is_phone_size ? (
            <div className="cashier-cont__products__titleWrapper">
              <Typography.Text
                className="cashier-cont__products__titleWrapper__title"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                All The Products
              </Typography.Text>
            </div>
          ) : null}
          <div
            ref={productContRef}
            style={{
              gridTemplateColumns:
                !loading && products?.length
                  ? `repeat(auto-fit, minmax(${
                      isMobile ? "160px" : "179.3px"
                    }, ${
                      isMobile
                        ? 350
                        : 250 / productContRef?.current?.offsetWidth
                    }fr))`
                  : `repeat(auto-fit, minmax(${
                      isMobile ? "160px" : "179.3px"
                    },1fr))`,
            }}
            className={classNames("cashier-cont__products__cartsCont", {
              "cashier-cont__spinWrapper": loading || !products?.length,
            })}>
            {!products?.length && !loading ? (
              <CustomEmpty
                circleColor={token?.colorText ? token?.colorText : ""}
                boxBorderColor={token?.colorText ? token?.colorText : ""}
              />
            ) : !loading ? (
              products?.map((product) => (
                <ProductCard
                  product={product}
                  key={product?.id}
                  navigate={() => {
                    navigate(`/product/${product?.id}`, { state: product });
                  }}
                  haveDrawer={true}
                  openDrawer={openDrawer}
                />
              ))
            ) : (
              <Spin size="large" />
            )}
          </div>
          {is_phone_size ? (
            <Pagination
              className="cashier-cont__pagination"
              disabled={loading}
              pageSize={searchParams?.get("per_page") || 25}
              current={searchParams?.get("page") || 1}
              defaultCurrent={searchParams?.get("page") || 1}
              total={meta?.count ? meta?.count : ""}
              responsive={true}
              onChange={handlePagination}
              showQuickJumper={width < 584 && width >= 577 ? false : true}
              showSizeChanger={false}
            />
          ) : null}
          <CreateOrder
            token={token}
            isMobile={isMobile}
            openNotificationWithIcon={openNotificationWithIcon}
          />
        </div>
        {!is_phone_size ? (
          <Pagination
            className="cashier-cont__pagination"
            disabled={loading}
            pageSize={searchParams?.get("per_page") || 25}
            current={searchParams?.get("page") || 1}
            defaultCurrent={searchParams?.get("page") || 1}
            total={meta?.count ? meta?.count : ""}
            responsive={true}
            onChange={handlePagination}
            showQuickJumper={width < 584 && width >= 577 ? false : true}
            showSizeChanger={false}
          />
        ) : null}
        <ProductDrawer
          isDrawerOpened={isDrawerOpened}
          close={() => {
            setIsDrawerOpened(false);
            setTimeout(() => {
              setSelectedProduct(null);
            }, 300);
          }}
          product={selectedProduct}
        />
        <DeskNumber
          token={token}
          title="Payment Desk"
          isOpened={is_desk_number_modal_opened}
          onCancel={() => {
            set_is_desk_number_modal_opened(false);
          }}
        />
      </Content>
    </>
  );
}
