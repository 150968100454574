import React from "react";
import "./Products.scss";
import { Content } from "antd/es/layout/layout";
import {
  Typography,
  Input,
  Select,
  Button,
  Dropdown,
  Pagination,
  Spin,
} from "antd";
import {
  CustomEmpty,
  ProductCard,
  ProductDrawer,
  PromoCodesSlider,
} from "../../components";
import {
  SearchOutlined,
  FunnelPlotOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { invertTheme } from "../../config/theme";
import { ProductFilters } from "./components";
import useProducts from "../../hooks/useProducts";
import classNames from "classnames";
import Utils from "../../utils";

export default function Products() {
  const {
    contextHolder,
    token,
    isAuthenticated,
    isMobile,
    meta,
    loading,
    arrangeOptions,
    searchParams,
    setUrlSearchParams,
    setIsFilterOpened,
    isFilterOpened,
    onSearchInpChange,
    searchVal,
    onOrderChange,
    onOrderClear,
    categories,
    catgeoriesLoading,
    onCategoryChange,
    brands,
    brandsLoading,
    onBrandChange,
    onGeneticsChange,
    priceRange,
    onPriceRangeChange,
    onCheckboxChange,
    maxPrice,
    productContRef,
    products,
    navigate,
    openDrawer,
    handlePagination,
    width,
    isDrawerOpened,
    setIsDrawerOpened,
    setSelectedProduct,
    selectedProduct,
  } = useProducts();

  return (
    <>
      {contextHolder}
      <Content
        className="products-cont"
        style={{
          backgroundColor: token?.Layout?.contentBg,
        }}>
        <PromoCodesSlider isAuthenticated={isAuthenticated} token={token} />
        <div className="products-cont__globalFilters">
          {isMobile ? (
            <div className="products-cont__globalFilters__productsCountWrapper">
              <div className="products-cont__globalFilters__productsCountWrapper__countCont">
                <div className="products-cont__globalFilters__productsCountWrapper__textsWrapper">
                  <Typography.Text
                    className="products-cont__globalFilters__productsCountWrapper__text"
                    style={{
                      color: token?.Typography?.mainColorText,
                    }}>
                    All Products
                  </Typography.Text>
                  <Typography.Text
                    className="products-cont__globalFilters__productsCountWrapper__text"
                    style={{
                      color: token?.Typography?.colorPrimaryText,
                    }}>
                    {meta?.count ? meta?.count : 0}
                  </Typography.Text>
                  <Typography.Text
                    className="products-cont__globalFilters__productsCountWrapper__text"
                    style={{
                      color: token?.Typography?.mainColorText,
                    }}>
                    results
                  </Typography.Text>
                </div>
                <Dropdown
                  className="products-cont__globalFilters__productsCountWrapper__countCont__dropDown"
                  trigger={["click"]}
                  disabled={loading}
                  menu={{
                    items: arrangeOptions,
                    onClick: ({ key }) => {
                      if (key === "ascending") {
                        setUrlSearchParams({
                          page: 1,
                          per_page: 25,
                          order_by: "price",
                        });
                      } else if (key === "descending") {
                        setUrlSearchParams({
                          page: 1,
                          per_page: 25,
                          order_by: "-price",
                        });
                      } else {
                        setUrlSearchParams({
                          page: 1,
                          per_page: 25,
                          order_by: undefined,
                        });
                      }
                    },
                  }}>
                  <div className="products-cont__globalFilters__productsCountWrapper__countCont__dropDown__content">
                    <Typography.Text
                      className="products-cont__globalFilters__productsCountWrapper__countCont__dropDown__content__text"
                      style={{
                        color: token?.Typography?.mainColorText,
                      }}>
                      {searchParams.get("order_by") === "price"
                        ? "Ascending"
                        : searchParams.get("order_by") === "-price"
                        ? "Descending"
                        : "Sort By"}
                    </Typography.Text>
                    <DownOutlined
                      style={{
                        fontSize: "14px",
                        color: token?.icons?.whiteIcon,
                      }}
                    />
                  </div>
                </Dropdown>
              </div>
              <Button
                type={isFilterOpened ? "primary" : ""}
                icon={
                  <FunnelPlotOutlined
                    style={{
                      color: isFilterOpened
                        ? token?.icons?.bgColorIcon
                        : token?.icons?.whiteIcon,
                      fontSize: "16px",
                    }}
                  />
                }
                size="small"
                onClick={() => {
                  setIsFilterOpened(!isFilterOpened);
                }}
              />
            </div>
          ) : (
            <div className="products-cont__globalFilters__productsCountWrapper">
              <Typography.Text
                className="products-cont__globalFilters__productsCountWrapper__text"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                All Products
              </Typography.Text>
              <div className="products-cont__globalFilters__productsCountWrapper__textsWrapper">
                <Typography.Text
                  className="products-cont__globalFilters__productsCountWrapper__text"
                  style={{
                    color: token?.Typography?.colorPrimaryText,
                  }}>
                  {meta?.count ? meta?.count : 0}
                </Typography.Text>
                <Typography.Text
                  className="products-cont__globalFilters__productsCountWrapper__text"
                  style={{
                    color: token?.Typography?.mainColorText,
                  }}>
                  results
                </Typography.Text>
              </div>
            </div>
          )}
          <div className="products-cont__globalFilters__filtersWrapper">
            {isMobile && !isFilterOpened ? null : (
              <Input
                className="products-cont__globalFilters__filtersWrapper__searchInp"
                size="large"
                placeholder="Brands, products, retailers and more"
                prefix={<SearchOutlined />}
                allowClear
                onChange={onSearchInpChange}
                value={searchVal}
              />
            )}
            {isMobile ? null : (
              <div className="products-cont__globalFilters__filtersWrapper__selectWrapper">
                <Typography.Text
                  className="products-cont__globalFilters__filtersWrapper__selectWrapper__text"
                  style={{
                    color: token?.Typography?.mainColorText,
                  }}>
                  Sort By :
                </Typography.Text>
                <Select
                  className="products-cont__globalFilters__filtersWrapper__selectWrapper__select"
                  placeholder="Sort by"
                  size="large"
                  options={[
                    { label: "Ascending", value: "ascending" },
                    { label: "Descending", value: "descending" },
                  ]}
                  allowClear
                  onChange={onOrderChange}
                  disabled={loading}
                  onClear={onOrderClear}
                  value={
                    searchParams?.get("order_by") === "price"
                      ? "ascending"
                      : searchParams?.get("order_by") === "-price"
                      ? "descending"
                      : undefined
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="products-cont__products">
          <ProductFilters
            token={token}
            invertTheme={invertTheme}
            isMobile={isMobile}
            isFilterOpened={isFilterOpened}
            loading={loading}
            categories={categories}
            catgeoriesLoading={catgeoriesLoading}
            selectedCategories={searchParams?.get("category")?.split(",") || []}
            onCategoryChange={onCategoryChange}
            filterOption={Utils?.filterOption}
            brands={brands}
            brandsLoading={brandsLoading}
            selectedBrands={searchParams?.get("brand_ids")?.split(",") || []}
            onBrandChange={onBrandChange}
            selectedGenetics={searchParams?.get("genetics")?.split(",") || []}
            onGeneticsChange={onGeneticsChange}
            priceRange={priceRange}
            onPriceRangeChange={onPriceRangeChange}
            isOnsale={Boolean(searchParams?.get("sale")) || false}
            onCheckboxChange={onCheckboxChange}
            maxPrice={maxPrice}
          />
          {isMobile ? (
            <div className="products-cont__products__titleWrapper">
              <Typography.Text
                className="products-cont__products__titleWrapper__title"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                All The Products
              </Typography.Text>
            </div>
          ) : null}
          <div
            ref={productContRef}
            style={{
              gridTemplateColumns:
                !loading && products?.length
                  ? `repeat(auto-fit, minmax(${
                      isMobile ? "168px" : "179.3px"
                    }, ${
                      isMobile
                        ? 350
                        : 250 / productContRef?.current?.offsetWidth
                    }fr))`
                  : `repeat(auto-fit, minmax(${
                      isMobile ? "168px" : "179.3px"
                    },1fr))`,
            }}
            className={classNames("products-cont__products__cartsCont", {
              "products-cont__spinWrapper": loading || !products?.length,
            })}>
            {!products?.length && !loading ? (
              <CustomEmpty
                circleColor={token?.colorText ? token?.colorText : ""}
                boxBorderColor={token?.colorText ? token?.colorText : ""}
              />
            ) : !loading ? (
              products?.map((product) => (
                <ProductCard
                  product={product}
                  key={product?.id}
                  navigate={() => {
                    navigate(`product/${product?.id}`, { state: product });
                  }}
                  haveDrawer={true}
                  openDrawer={openDrawer}
                />
              ))
            ) : (
              <Spin size="large" />
            )}
          </div>
        </div>
        <Pagination
          className="products-cont__pagination"
          disabled={loading}
          pageSize={searchParams?.get("per_page") || 25}
          current={searchParams?.get("page") || 1}
          defaultCurrent={searchParams?.get("page") || 1}
          total={meta?.count ? meta?.count : ""}
          responsive={true}
          onChange={handlePagination}
          showQuickJumper={width < 584 && width >= 577 ? false : true}
          showSizeChanger={false}
        />
        <ProductDrawer
          isDrawerOpened={isDrawerOpened}
          close={() => {
            setIsDrawerOpened(false);
            setTimeout(() => {
              setSelectedProduct(null);
            }, 300);
          }}
          product={selectedProduct}
        />
      </Content>
    </>
  );
}
