import React, { useEffect, useState } from "react";
import { Statistic } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import Utils from "../../../../utils";

export default function Timer({ token }) {
  const [time, setTime] = useState(Utils?.formatDateTime(new Date()));

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Utils?.formatDateTime(new Date())); // Update time every second
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <Statistic
      style={{
        marginLeft: "auto",
      }}
      value={time}
      valueStyle={{
        fontSize: "24px",
        fontWeight: "bold",
        color: token?.Typography?.mainColorText,
      }}
      prefix={<ClockCircleOutlined />}
    />
  );
}
