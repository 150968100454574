import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import axiosClient from "../../common/api/request";
import LocalStorageService from "../../common/services/LocalStorageService";

export const postCahierDeskNumber = createAsyncThunk(
  "post_cashier_desk_number",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/account/cashier/desk/`,
        params,
        config
      );
      // store cashiers's desk number in local storage
      LocalStorageService?.set(
        "has_cashier_entered_desk_number",
        data?.desk_number
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue({
          SERVER_ERROR: error?.message,
        });
      }
    }
  }
);
