import React, { useEffect, useMemo } from "react";
import "./App.scss";
import { ConfigProvider, Layout } from "antd";
import { invertTheme } from "../config/theme";
import { AppFooter, AppHeader, CustomEmpty } from "../components";
import { Routes, Route } from "react-router-dom";
import RolesHelper from "../common/services/permissions/RolesHelper";
import {
  Cashier,
  Checkout,
  Favorites,
  NotFoundPage,
  Products,
  SingleProduct,
  SecurityGuy,
  Profile,
  ResetPassword,
  ViewQueueWithDeskNumber,
} from "../pages";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "../features/auth/authActions";
import { getShopDetails } from "../features/shopDetails/shopDetailsActions";
// import { useLocation } from 'react-router-dom';
import { AgeConfirmationModal } from "../modals";

export default function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  const role = useMemo(() => {
    return isAuthenticated ? RolesHelper?.getRole() : {};
  }, [isAuthenticated]);
  // const location = useLocation();
  const pageVisible = RolesHelper?.getPagesVisibility();

  const getComponentByRole = (role) => {
    if (role?.is_cashier && isAuthenticated) return <Cashier />;
    if (role?.is_security && isAuthenticated) return <SecurityGuy />;
    if (role?.is_kiosk && isAuthenticated) return <Products />;
    if (role?.is_queue_viewer && isAuthenticated)
      return <ViewQueueWithDeskNumber />;
    return <Products />;
  };

  useEffect(() => {
    if (isAuthenticated && !role?.is_queue_viewer) {
      dispatch(getMe());
    }
    dispatch(getShopDetails());
  }, [dispatch, isAuthenticated, role]);

  // useEffect(() => {
  //   window.parent.postMessage && window.parent.postMessage(JSON.stringify({ type: "pathname", "data": location.pathname }), "*");
  // }, [location.pathname]);

  return (
    <Layout
      className="App-cont"
      style={
        role?.is_queue_viewer
          ? {
              minHeight: "100dvh",
            }
          : null
      }>
      <ConfigProvider theme={invertTheme} renderEmpty={CustomEmpty}>
        <AppHeader />
        <Routes>
          <Route path="/" element={getComponentByRole(role)} />
          <Route path="login" element={getComponentByRole(role)} />
          {pageVisible?.is_favorites_visible ? (
            <Route path="favorites" element={<Favorites />} />
          ) : null}
          {pageVisible?.is_single_product_visible ? (
            <Route path="product/:productId" element={<SingleProduct />} />
          ) : null}
          {pageVisible?.is_checkout_visible ? (
            <Route path="checkout" element={<Checkout />} />
          ) : null}
          {pageVisible?.is_profile_visible ? (
            <Route path="profile" element={<Profile />} />
          ) : null}
          <Route
            path="password_reset/:user_id/:token"
            element={<ResetPassword />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <AppFooter />
        <AgeConfirmationModal />
      </ConfigProvider>
    </Layout>
  );
}
