import React, { useState } from "react";
import "./RelatedProducts.scss";
import { Typography, Spin } from "antd";
import {
  CustomEmpty,
  ProductCard,
  ProductDrawer,
} from "../../../../components";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useNavigate } from "react-router";

export default function RelatedProducts({ token, singleProductHeaderRef }) {
  const {
    loading: relatedProductsLoading,
    relatedItems: { results: relatedProducts },
  } = useSelector((state) => state?.relatedProducts);
  const navigate = useNavigate();
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const openDrawer = (product) => {
    setIsDrawerOpened(true);
    setSelectedProduct(product);
  };

  return (
    <div className="relatedProducts-cont">
      <Typography.Text
        className="relatedProducts-cont__title"
        style={{
          color: token?.Typography?.mainColorText,
        }}>
        Related Products
      </Typography.Text>
      {relatedProductsLoading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Spin size="large" />
        </div>
      ) : !relatedProducts?.length ? (
        <CustomEmpty />
      ) : (
        <Swiper
          className="mySwiper"
          style={{
            width: "100%",
            // borderRadius: "8px",
          }}
          modules={[Navigation]}
          breakpoints={{
            350: {
              slidesPerView: 1.7,
            },
            450: {
              slidesPerView: 2.2,
            },
            500: {
              slidesPerView: 2.65,
            },
            650: {
              slidesPerView: 3.3,
            },
            800: {
              slidesPerView: 4,
            },
            1100: {
              slidesPerView: 4.5,
            },
            1300: {
              slidesPerView: 5,
            },
            1500: {
              slidesPerView: 6,
            },
            1700: {
              slidesPerView: 7.5,
            },
            2500: {
              slidesPerView: 10,
            },
          }}
          spaceBetween={16}
          pagination={{
            type: "fraction",
          }}
          navigation={true}>
          {relatedProducts?.map((product, index) => (
            <SwiperSlide
              key={index}
              style={{
                height: "auto",
                minHeight: "auto",
              }}>
              <ProductCard
                key={product?.id}
                product={product}
                fromSlider={true}
                navigate={() => {
                  navigate(`/product/${product?.id}`, { state: product });
                  if (singleProductHeaderRef?.current) {
                    singleProductHeaderRef?.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  }
                }}
                haveDrawer={true}
                openDrawer={openDrawer}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <ProductDrawer
        isDrawerOpened={isDrawerOpened}
        close={() => {
          setIsDrawerOpened(false);
          setTimeout(() => {
            setSelectedProduct(null);
          }, 300);
        }}
        product={selectedProduct}
      />
    </div>
  );
}
