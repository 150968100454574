import React, { useState } from "react";
import "./CashierCheckout.scss";
import { Card, Typography, Select, Divider, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { makeOrder } from "../../../../features/order/orderActions";
import { setCard } from "../../../../features/card/cardSlice";
import Utils from "../../../../utils";

export default function CashierCheckout({
  token,
  isMobile,
  selectedPromoCode,
  setSelectedPromoCode,
  orderCalculation,
  openNotificationWithIcon,
  set_order_patient,
  order_patient,
}) {
  const { items } = useSelector((state) => state?.card);
  const {
    codes: { results: promoCodes },
    loading: promoCodesLoading,
  } = useSelector((state) => state?.promoCodes);
  const dispatch = useDispatch();
  const [orderLoading, setOrderLoading] = useState(false);

  const foundPromoCode = selectedPromoCode
    ? promoCodes?.find(
        (code) => code?.id?.toString() === selectedPromoCode?.value?.toString()
      )
    : null;

  const getPayementDetailPrice = (price) => {
    if (!isNaN(price)) {
      return Utils?.roundNumber(price);
    }
  };

  const showTaxes = (taxes) => {
    const res = [];
    let i = 0;
    for (let key in taxes) {
      if (key !== "discount_total" && key !== "grand_total") {
        const formattedKey = key
          ?.split("_") // Split key by underscores
          ?.map(
            (word) =>
              word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase()
          ) // Capitalize each word
          ?.join(" ");
        if (key === "promo_code") {
          res?.push(
            <div className="cashierCheckout-cont__textWrapper" key={i}>
              <Typography.Text
                className="cashierCheckout-cont__text"
                style={{
                  color: token?.Typography?.colorPrimaryText,
                }}>
                {formattedKey}
              </Typography.Text>
              <Typography.Text
                className="cashierCheckout-cont__title"
                style={{
                  color: token?.Typography?.colorPrimaryText,
                }}>
                {taxes[key] || taxes[key] === 0
                  ? `${
                      taxes[key] === 0
                        ? getPayementDetailPrice(taxes[key])
                        : "-" + getPayementDetailPrice(taxes[key])
                    }`
                  : "$0"}
              </Typography.Text>
            </div>
          );
        } else {
          res?.push(
            <div className="cashierCheckout-cont__textWrapper" key={i}>
              <Typography.Text
                className="cashierCheckout-cont__text"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                {formattedKey}
              </Typography.Text>
              <Typography.Text
                className="cashierCheckout-cont__title"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                {taxes[key] || taxes[key] === 0
                  ? getPayementDetailPrice(taxes[key])
                  : "$0"}
              </Typography.Text>
            </div>
          );
        }

        i += 1;
      }
    }
    return res;
  };

  const checkout = () => {
    setOrderLoading(true);
    dispatch(
      makeOrder({
        customer: order_patient?.id,
        phone_number: order_patient?.phone_number, // TODO check
        order_items: items?.map((el) => ({
          menu_item: el?.menu_item,
          quantity: el?.quantity,
          variant: el?.variant,
        })),
        grand_total: orderCalculation?.grand_total,
        promo_code: selectedPromoCode ? selectedPromoCode?.value : undefined,
      })
    ).then((res) => {
      if (res) {
        setOrderLoading(false);
        if (res?.type === "makeOrder/fulfilled") {
          setSelectedPromoCode(undefined);
          dispatch(setCard([]));
          set_order_patient(null);
          openNotificationWithIcon(
            "success",
            "Create Order",
            "Your order has been successfully created."
          );
        } else if (res?.type === "makeOrder/rejected") {
          if (
            typeof res?.payload === "object" &&
            !Array.isArray(res?.payload)
          ) {
            for (let key in res?.payload) {
              if (
                res?.payload[key] &&
                res?.payload[key][0] &&
                typeof res?.payload[key][0] === "string"
              ) {
                openNotificationWithIcon("error", key, res?.payload[key]);
                break;
              } else {
                openNotificationWithIcon(
                  "error",
                  "Error",
                  "Your order has not been created."
                );
                break;
              }
            }
          } else {
            openNotificationWithIcon(
              "error",
              "Error",
              "Your order has not been created."
            );
          }
        }
      }
    });
  };

  return (
    <>
      <Card
        className="cashierCheckout-cont"
        style={{
          height: "fit-content",
          border: "none",
          backgroundColor: token?.Card?.colorBgCard,
        }}
        styles={{
          body: isMobile
            ? {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "16px",
              }
            : {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                padding: "32px",
              },
        }}>
        <div className="cashierCheckout-cont__promoCodeWrapper">
          <Typography.Text
            className="cashierCheckout-cont__text"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Promo Code
          </Typography.Text>
          <Select
            className="cashierCheckout-cont__promoCodeWrapper__promoInp"
            placeholder="Promo Code"
            value={selectedPromoCode ? selectedPromoCode : undefined}
            options={promoCodes?.map((promoCode) => ({
              label: promoCode?.code ? promoCode?.code : "",
              value: promoCode?.id ? promoCode?.id : "",
            }))}
            allowClear
            onClear={() => {
              setSelectedPromoCode(undefined);
            }}
            onChange={(_, val) => {
              setSelectedPromoCode(val);
            }}
            disabled={orderLoading}
            loading={promoCodesLoading}
          />
          {selectedPromoCode ? (
            <Typography.Text
              className="cashierCheckout-cont__text"
              style={{
                color: token?.Typography?.colorPrimaryText,
                fontSize: "14px",
              }}
              ellipsis={{
                tooltip: {
                  title: foundPromoCode?.description
                    ? foundPromoCode?.description
                    : "No Description",
                },
              }}>
              {foundPromoCode?.description
                ? foundPromoCode?.description
                : "No Description"}
            </Typography.Text>
          ) : null}
        </div>
        <Divider
          type="horizontal"
          className="cashierCheckout-cont__divider"
          style={{
            borderColor: token?.Layout?.footerBorderTopColor,
          }}
        />
        <div className="cashierCheckout-cont__keyValPairWrapper">
          <Typography.Text
            className="cashierCheckout-cont__text"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Order Summary
          </Typography.Text>
          {showTaxes(orderCalculation)}
        </div>
        <Divider
          type="horizontal"
          className="cashierCheckout-cont__divider"
          style={{
            borderColor: token?.Layout?.footerBorderTopColor,
          }}
        />
        <div className="cashierCheckout-cont__textWrapper">
          <Typography.Text
            className="cashierCheckout-cont__text"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Total
          </Typography.Text>
          <Typography.Text
            className="cashierCheckout-cont__title"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            {orderCalculation?.grand_total ||
            orderCalculation?.grand_total === 0
              ? getPayementDetailPrice(orderCalculation?.grand_total)
              : "$0"}
          </Typography.Text>
        </div>
        <Button
          onClick={() => {
            checkout();
          }}
          type="primary"
          disabled={!items?.length || !order_patient}
          loading={orderLoading}>
          Proceed To Checkout
        </Button>
      </Card>
    </>
  );
}
