import React, { useCallback, useMemo } from "react";
import "./ViewQueueWithDeskNumber.scss";
import { Content } from "antd/es/layout/layout";
import { theme, notification } from "antd";
import { useSelector } from "react-redux";
import { Timer, QueueProcessing } from "./components";
import RolesHelper from "../../common/services/permissions/RolesHelper";

export default function ViewQueueWithDeskNumber() {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback(
    (type, title, text) => {
      api[type]({
        message: title,
        description: text,
        placement: "top",
      });
    },
    [api]
  );
  const { isAuthenticated } = useSelector((state) => state?.auth);
  const role = useMemo(() => {
    return isAuthenticated ? RolesHelper?.getRole() : {};
  }, [isAuthenticated]);

  return (
    <>
      {contextHolder}
      <Content
        className="queueViewer-cont"
        style={{
          backgroundColor: token?.Layout?.contentBg,
        }}>
        <Timer token={token} />
        <div className="queueViewer-cont__screen">
          <QueueProcessing
            token={token}
            openNotificationWithIcon={openNotificationWithIcon}
            role={role}
          />
        </div>
      </Content>
    </>
  );
}
