import React from "react";
import "./Counter.scss";
import { theme, Button } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

export default function Counter({
  count,
  onCauntChnage,
  style,
  allowDecrement,
  openConfirmModal,
  stop_propogation,
}) {
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <div className="counter-cont">
      <Button
        className="counter-cont__btn1"
        disabled={allowDecrement ? false : count <= 1}
        onClick={(e) => {
          if (stop_propogation) {
            e.stopPropagation();
          }
          if (count > 1) {
            onCauntChnage(count - 1);
          }
          if (
            allowDecrement &&
            typeof openConfirmModal === "function" &&
            count <= 1
          ) {
            openConfirmModal();
          }
        }}
        icon={
          <MinusOutlined
            style={{
              fontSize: "24px",
              color: token?.icons?.whiteIcon,
            }}
          />
        }
      />
      <div
        className="counter-cont__countWrapper"
        style={
          style
            ? {
                border: `1px solid ${token?.icons?.whiteIcon}`,
                ...style,
              }
            : {
                border: `1px solid ${token?.icons?.whiteIcon}`,
              }
        }>
        {count}
      </div>
      <Button
        className="counter-cont__btn2"
        onClick={(e) => {
          if (stop_propogation) {
            e.stopPropagation();
          }
          onCauntChnage(count + 1);
        }}
        icon={
          <PlusOutlined
            style={{
              fontSize: "24px",
              color: token?.icons?.whiteIcon,
            }}
          />
        }
      />
    </div>
  );
}
