import React, { useState } from "react";
import "./PatientCard.scss";
import { Typography, Button, Spin } from "antd";
import {
  ForwardOutlined,
  SelectOutlined,
  UsergroupAddOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import QueueDrawer from "../QueueDrawer/QueueDrawer";
import { ConfirmModal } from "../../../../modals";
import { useSelector, useDispatch } from "react-redux";
import {
  postPatientQueue,
  serveQueuedPatient,
} from "../../../../features/patient/patientActions";
import { resetPatientError } from "../../../../features/patient/patientSLice";
import { CustomerCard } from "../../../../components";
import LocalStorageService from "../../../../common/services/LocalStorageService";

export default function PatientCard({
  token,
  order_patient,
  set_order_patient,
  openNotificationWithIcon,
}) {
  const { error, loading } = useSelector((state) => state?.patient);
  const dispatch = useDispatch();
  const [is_queue_drawer_opened, set_is_queue_drawer_opened] = useState(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [isReaddModalOpened, setIsReaddModalOpened] = useState(false);
  const [call_handle_next_patient, set_call_handle_next_patient] =
    useState(false);

  const openQueueDrawer = () => {
    set_is_queue_drawer_opened(true);
    if (error) {
      dispatch(resetPatientError());
    }
  };

  const closeQueueDrawer = () => {
    set_is_queue_drawer_opened(false);
  };

  const handlePatient = (patient, cb) => {
    set_order_patient(patient);
    if (typeof cb === "function") {
      cb();
    }
  };

  const handleNextPatient = () => {
    if (LocalStorageService?.get("has_cashier_entered_desk_number")) {
      dispatch(serveQueuedPatient())?.then((response) => {
        if (response?.type === "serve_queued_patient/fulfilled") {
          if (Array.isArray(response?.payload) && !response?.payload?.length) {
            openNotificationWithIcon(
              "warning",
              "Warning",
              "No patients in queue."
            );
          } else {
            set_order_patient(response?.payload);
          }
        } else if (response?.type === "delete_queued_patient/rejected") {
          const error = response?.payload;
          if (error?.Error) {
            openNotificationWithIcon("error", "Server Error", error?.Error);
          } else {
            for (let key in error) {
              openNotificationWithIcon("error", "Error", error[key]);
            }
          }
          dispatch(resetPatientError());
        }
      });
    } else {
      openNotificationWithIcon(
        "warning",
        "warning",
        "Please set cashier desk number."
      );
    }
  };

  const moveToQueue = () => {
    dispatch(
      postPatientQueue({
        id: order_patient?.id,
      })
    )?.then((res) => {
      if (res?.type === "post_patient_queue/fulfilled") {
        set_order_patient(null);
        if (call_handle_next_patient) {
          set_call_handle_next_patient(false);
        }
      } else if (res?.type === "post_patient_queue/rejected") {
        const error = res?.payload;
        for (let key in error) {
          if (typeof error[key] === "string" && error[key]) {
            openNotificationWithIcon("error", "Error", error[key]);
            break;
          } else if (Array.isArray(error[key]) && error[key].length) {
            openNotificationWithIcon("error", "Error", error[key][0]);
            break;
          }
        }
      }
    });
  };

  return (
    <Spin spinning={loading && !is_queue_drawer_opened}>
      <div className="patientCardCont">
        <div className="patientCardCont__wrapper">
          <Typography.Text
            className="patientCardCont__text"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Patient
          </Typography.Text>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}>
            <Button
              size="small"
              title="View Patients Queue."
              icon={
                <SelectOutlined
                  style={{
                    fontSize: "18px",
                  }}
                />
              }
              onClick={openQueueDrawer}
            />
            <Button
              size="small"
              title="Handle Next Patient."
              icon={
                <ForwardOutlined
                  style={{
                    fontSize: "18px",
                  }}
                />
              }
              onClick={() => {
                if (!order_patient) {
                  handleNextPatient();
                } else {
                  setIsConfirmModalOpened(true);
                  set_call_handle_next_patient(true);
                }
              }}
            />
          </div>
        </div>
        <CustomerCard
          customer={order_patient}
          onCloseClick={() => {
            setIsConfirmModalOpened(true);
            set_call_handle_next_patient(false);
          }}
        />
        <QueueDrawer
          token={token}
          isOpened={is_queue_drawer_opened}
          close={closeQueueDrawer}
          handlePatient={handlePatient}
          openNotificationWithIcon={openNotificationWithIcon}
          order_patient={order_patient}
          set_order_patient={set_order_patient}
        />
        <ConfirmModal
          token={token}
          isOpened={isConfirmModalOpened}
          title={"Are you sure"}
          subTitle={
            call_handle_next_patient
              ? `By clicking yes the current patient will be removed from queue and will be called the next patient ?`
              : `You want to unhandle the patient ?`
          }
          icon={
            <WarningOutlined
              style={{ fontSize: "24px", color: token?.colorWarning }}
            />
          }
          onCancel={() => {
            setIsConfirmModalOpened(false);
            if (call_handle_next_patient) {
              set_call_handle_next_patient(false);
            }
          }}
          onOk={() => {
            setIsConfirmModalOpened(false);
            set_order_patient(null);
            if (call_handle_next_patient) {
              set_call_handle_next_patient(false);
              handleNextPatient();
            }
          }}
          with_custom_footer={call_handle_next_patient ? false : true}
          onCustomButtonClick={
            call_handle_next_patient
              ? null
              : () => {
                  setIsReaddModalOpened(true);
                  setIsConfirmModalOpened(false);
                }
          }
          custom_btn_text={call_handle_next_patient ? null : "Move To Queue"}
        />
        <ConfirmModal
          token={token}
          isOpened={isReaddModalOpened}
          title={"Are you sure"}
          subTitle={`You want to move the patient to queue ?`}
          icon={<UsergroupAddOutlined style={{ fontSize: "24px" }} />}
          onCancel={() => {
            setIsReaddModalOpened(false);
            if (call_handle_next_patient) {
              set_call_handle_next_patient(false);
            }
          }}
          onOk={() => {
            moveToQueue();
            setIsReaddModalOpened(false);
          }}
        />
      </div>
    </Spin>
  );
}
