import React, { useState } from "react";
import "./SecurityGuyHeader.scss";
import { Button, Input, AutoComplete } from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  get_queued_patients,
  getPatients,
  postPatientQueue,
} from "../../../../features/patient/patientActions";
import { CustomEmpty } from "../../../../components";
import { ConfirmModal } from "../../../../modals";
import Utils from "../../../../utils";

const doSearch = (value, cb) => {
  cb({
    page: 1,
    per_page: 10,
    name: value ? value : undefined,
  });
};
const debouncedSearch = Utils?.debounce(doSearch, 500);

export default function SecurityGuyHeader({ token, set_is_patient_opened }) {
  const dispatch = useDispatch();
  const [patients, setPatients] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);

  const onSearch = (value) => {
    setSearchVal(value);
    debouncedSearch(value, () => {
      if (value) {
        dispatch(
          getPatients({
            page: 1,
            per_page: 10,
            name: value ? value?.toLowerCase() : undefined,
          })
        )?.then((data) => {
          if (data?.type === "get_patients/fulfilled") {
            if (data?.payload) {
              setPatients(data?.payload?.results);
            }
          }
        });
      } else {
        setPatients([]);
      }
    });
  };

  const onSelect = (_, option) => {
    setSelectedPatient({
      id: option?.key,
      label: option?.label ? option?.label : "",
    });
    setIsConfirmModalOpened(true);
  };

  return (
    <div className="securityGuyHeader-cont">
      <AutoComplete
        className="securityGuyHeader-cont__search"
        style={{
          height: "40px",
        }}
        options={Utils?.madeUnique(patients)?.map((patient) => ({
          label: `${patient?.first_name ? patient?.first_name : ""} ${
            patient?.last_name ? patient?.last_name : ""
          }
          ${
            patient?.phone_number
              ? `+1 ${Utils?.format_phone_number(patient?.phone_number)}`
              : ""
          }`,
          value: `${patient?.first_name ? patient?.first_name : ""} ${
            patient?.last_name ? patient?.last_name : ""
          }`,
          key: `${patient?.id}`,
        }))}
        value={searchVal}
        onSearch={onSearch}
        onSelect={onSelect}
        notFoundContent={<CustomEmpty />}>
        <Input
          style={{
            width: "100%",
          }}
          size="large"
          placeholder="Search patient"
          prefix={<SearchOutlined />}
          allowClear
        />
      </AutoComplete>
      <Button
        className="securityGuyHeader-cont__btn"
        size="large"
        icon={<PlusOutlined />}
        onClick={() => {
          set_is_patient_opened(true);
        }}>
        Create New
      </Button>
      <ConfirmModal
        token={token}
        isOpened={isConfirmModalOpened}
        title={"Are you sure"}
        subTitle={`You want to add in queue  ${
          selectedPatient?.label ? selectedPatient?.label : ""
        }
      ?`}
        icon={<UsergroupAddOutlined style={{ fontSize: "24px" }} />}
        onCancel={() => {
          setIsConfirmModalOpened(false);
          setSelectedPatient(null);
        }}
        onOk={() => {
          setIsConfirmModalOpened(false);
          setSelectedPatient(null);
          dispatch(
            postPatientQueue({
              id: selectedPatient?.id,
            })
          )?.then((res) => {
            if (res?.type === "post_patient_queue/fulfilled") {
              dispatch(get_queued_patients());
            }
          });
        }}
      />
    </div>
  );
}
