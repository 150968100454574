import { formatPhoneNumber } from "react-phone-number-input";
import dayjs from "dayjs";

const Utils = {
  numberValidator: (detail, value) => {
    const phoneNumberPattern = /^[0-9]+$/;
    if (!value) {
      return Promise.reject(
        new Error(
          `Please enter your ${
            detail?.field === "phone_number" || detail?.field === "phone2"
              ? "phone number!"
              : detail?.field === "zip_code" || detail?.field === "zip_code2"
              ? "zip code"
              : detail?.field === "appartment2" ||
                detail?.field === "appartment2"
              ? "appartment"
              : "appartment"
          }`
        )
      );
    }
    if (value && !isNaN(value) && parseInt(value, 10) < 0) {
      return Promise.reject(
        new Error(
          `${
            detail?.field === "phone_number" || detail?.field === "phone2"
              ? "Phone number"
              : detail?.field === "zip_code" || detail?.field === "zip_code2"
              ? "Zip code"
              : detail?.field === "appartment" ||
                detail?.field === "appartment2"
              ? "Appartment"
              : ""
          } must be a positive number!`
        )
      );
    }
    if (!phoneNumberPattern.test(value)) {
      return Promise.reject(
        new Error(
          `${
            detail?.field === "phone_number" || detail?.field === "phone2"
              ? "Phone number"
              : detail?.field === "zip_code" || detail?.field === "zip_code2"
              ? "Zip code"
              : detail?.field === "appartment" ||
                detail?.field === "appartment2"
              ? "Appartment"
              : ""
          } must be integer!`
        )
      );
    }
    return Promise.resolve();
  },
  emailValidator: (_, value) => {
    return new Promise((resolve, reject) => {
      if (!value) {
        return reject("Please enter your email!");
      }
      if (!/\S+@\S+\.\S+/.test(value)) {
        return reject("Please enter valid email!");
      } else {
        return resolve();
      }
    });
  },
  positiveIntegerValidator: (_, value) => {
    return new Promise((resolve, reject) => {
      if (!value) {
        return reject(); // Just reject without a custom message
      }
      // Check if the value is a positive integer using regex
      if (!/^[1-9]\d*$/?.test(value)) {
        return reject("Please enter a positive integer!");
      } else {
        return resolve(); // If valid, resolve
      }
    });
  },
  debounce: (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  },
  roundNumber: (number) => {
    return new Intl.NumberFormat(
      "en-US",
      { style: "currency", currency: "USD" },
      { minimumFractionDigits: 2, maximumFractionDigits: 2, currency: "USD" }
    )
      .format(number)
      .replace(/,/g, " ");
  },
  getMinandMaxVariants: (arr) => {
    const newarr = [...arr];
    const sortarr = newarr?.sort((a, b) => a?.price - b?.price);
    return sortarr?.length > 1
      ? [sortarr[0], sortarr[sortarr?.length - 1]]
      : [sortarr[0]];
  },
  filterOption: (input, option) =>
    (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase()),
  findParentCategory: (product) => {
    return product?.categories?.length
      ? product?.categories?.find(
          (el) => el?.parent === null && el?.name?.toLowerCase() !== "featured"
        )
      : "";
  },
  mapperOfMeasurment: (unit) => {
    switch (unit) {
      case "kilograms":
        return "kg";
      case "kilogram":
        return "kg";
      case "milligrams":
        return "mg";
      case "milligram":
        return "mg";
      case "grams":
        return "g";
      case "gram":
        return "g";
      case "pounds":
        return "lb";
      case "pound":
        return "lb";
      case "ounces":
        return "oz";
      case "ounce":
        return "oz";
      default:
        return "";
    }
  },
  formatWeight: (weight) => {
    // Convert the number to a string
    const numberString = weight?.toString();

    // If the number has a decimal point, remove trailing zeros after the decimal
    if (numberString?.includes(".")) {
      return parseFloat(weight);
      // fixed by two variant parseFloat(parseFloat(weight).toFixed(2));
    }

    // If there's no decimal or it's already an integer, return the number as it is
    return weight;
  },
  showProductDetail(variant) {
    return this?.mapperOfMeasurment(variant?.unit)
      ? `${this?.formatWeight(
          variant?.name?.split(" ")[0]
        )} ${this?.mapperOfMeasurment(variant?.unit)}`?.replace(/\s+/g, "")
      : `${this?.formatWeight(variant?.name?.split(" ")[0])} ${
          variant?.unit
        }`?.replace(/\s+/g, "");
  },
  getMaxPrice: (arr) => {
    let maxPrice = 0;
    if (Array.isArray(arr)) {
      arr?.forEach((item) => {
        if (Array.isArray(item?.variants) && item?.variants?.length) {
          item?.variants?.forEach((variant) => {
            const price = parseFloat(variant?.price); // Convert price to number
            if (!isNaN(price) && price > maxPrice && isFinite(price)) {
              maxPrice = price;
            }
          });
        }
      });
    }

    return Number(maxPrice);
  },
  findVariantCountGt0: (variants) =>
    variants?.find((variant) => variant?.count > 0),
  getUrlParamsAsObject: (searchParams) => {
    if (!searchParams) return {}; // Return empty object if searchParams is null or undefined
    return Object.fromEntries(searchParams?.entries());
  },
  get_filtered_url_params: (old_url_params, new_url_params) => {
    if (!old_url_params && !new_url_params) return {};
    const old_params_as_object = this?.getUrlParamsAsObject(old_url_params);
    const filtered_params_as_object = Object?.fromEntries(
      Object?.entries({ ...old_params_as_object, ...new_url_params })?.filter(
        ([_, value]) => Boolean(value) || value === 0
      )
    );
    return filtered_params_as_object;
  },
  madeUnique: (items) => {
    return [...new Map(items?.map((item) => [item["id"], item]))?.values()];
  },
  getUrlInMap: (address) => {
    return `http://maps.apple.com/?q=${encodeURIComponent(address)}`;
  },
  format_phone_number: (phone_number) => {
    return phone_number ? formatPhoneNumber(phone_number) : "";
  },
  format_date: (date) => {
    return date ? dayjs(date)?.format("YYYY-MM-DD") : "";
  },
  formatDateTime: (date) => {
    return date ? dayjs(date).format("YYYY-MM-DD hh:mm:ss A") : "";
  },
  scrollIntoViewByClassName:(className)=>{
    const element = document?.querySelector(className); // Select the form
    if (element) {
      element?.scrollIntoView({ behavior: "smooth" });
    }
  }
};

export default Utils;
