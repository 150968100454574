import React, { useCallback, useEffect, useRef } from "react";
import { Form, Input, Button, Typography, Modal, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import "./Login.scss";
import { Content } from "antd/es/layout/layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { userLogin } from "../../features/auth/authActions";
import { resetAuthError } from "../../features/auth/authSlice";
import useWindowWidth from "../../hooks/useWindowWidth";
import Utils from "../../utils";

const Login = ({
  isOpened,
  title,
  setIsOpened,
  setIsRegisterOpened,
  setIsFrogetPasswordOpened,
  token,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const openMessage = useCallback(
    (type, text) => {
      messageApi.open({
        type: type,
        content: text,
      });
    },
    [messageApi]
  );
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const { width } = useWindowWidth();
  const { loading, error, success } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();

  const onFinish = (values) => {
    dispatch(userLogin(values))?.then((res) => {
      if (res?.type === "auth/login/fulfilled") {
        navigate("/");
      }
    });
  };

  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        if (typeof error !== "object") {
          formRef?.current?.setFields([
            {
              name: "password", // Field name you want to set the error for
              errors: [error], // Assuming your error has a 'message' field
            },
          ]);
        }
        if (error?.SERVER_ERROR) {
          openMessage("error", error?.SERVER_ERROR);
        }
      }
    }
  }, [dispatch, loading, error, isOpened, openMessage]);

  useEffect(() => {
    if (isOpened) {
      if (success) {
        setIsOpened(false);
        formRef.current.resetFields();
        dispatch(resetAuthError());
        if (pathname === "/login") {
          navigate("/");
        }
      }
    }
  }, [success, dispatch, isOpened, setIsOpened, pathname, navigate]);

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpened}
        title={title}
        destroyOnClose={true}
        onOk={() => {
          formRef.current.submit();
        }}
        okText="Save"
        centered
        footer={null}
        onCancel={() => {
          formRef?.current?.resetFields();
          setIsOpened(false);
          if (error) {
            dispatch(resetAuthError());
          }
          if (pathname === "/login") {
            navigate("/");
          }
        }}
        style={{
          contentBg: token?.Modal?.contentBg,
        }}
        className="addmenu-cont"
        cancelButtonProps={{
          className: "addmenu-cont__btn",
          size: "small",
        }}
        okButtonProps={{
          size: "small",
          htmlType: "submit",
          type: "primary",
          className: "addmenu-cont__btn",
        }}>
        <Content
          className="login-cont"
          style={{
            backgroundColor: token?.Content?.contentBg,
          }}>
          <div className="login-cont-form">
            <Form
              name="normal_login"
              className="login-form"
              ref={formRef}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    validator: Utils?.emailValidator,
                  },
                ]}>
                <Typography.Text
                  style={{
                    color: token?.Typography?.colorPrimaryText,
                  }}>
                  <Input
                    className="login-cont__globalFilters__filtersWrapper__searchInp"
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                    onChange={() => {
                      if (error) {
                        dispatch(resetAuthError());
                        formRef?.current?.setFields([
                          {
                            name: "password", // Field name you want to set the error for
                            errors: [], // Assuming your error has a 'message' field
                          },
                        ]);
                      }
                    }}
                  />
                </Typography.Text>
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}>
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                  onChange={() => {
                    if (error) {
                      dispatch(resetAuthError());
                    }
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Typography.Text
                  className="login-form-forgot"
                  style={{
                    color: token?.Typography?.colorPrimaryText,
                    cursor: "pointer",
                    width: width < 290 ? "100%" : "",
                    marginBottom: "4px",
                  }}
                  onClick={() => {
                    if (!loading) {
                      setIsOpened(false);
                      formRef?.current?.resetFields();
                      setIsFrogetPasswordOpened(true);
                      if (error) {
                        dispatch(resetAuthError());
                      }
                      if (pathname === "/login") {
                        navigate("/");
                      }
                    }
                  }}>
                  Forgot password
                </Typography.Text>
                <Button
                  style={{ height: 40, marginBottom: "4px" }}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  disabled={error}
                  loading={loading}>
                  Log in
                </Button>
                Or{" "}
                <Typography.Text
                  style={{
                    color: token?.Typography?.colorPrimaryText,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!loading) {
                      setIsOpened(false);
                      formRef?.current?.resetFields();
                      setIsRegisterOpened(true);
                      if (error) {
                        dispatch(resetAuthError());
                      }
                      if (pathname === "/login") {
                        navigate("/");
                      }
                    }
                  }}>
                  register now!
                </Typography.Text>
              </Form.Item>
            </Form>
          </div>
        </Content>
      </Modal>
    </>
  );
};

export default Login;
