export const theme = Object.freeze({
  token: {
    fontFamily: `-apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji'`,
    colorPrimary: "#61BC18",
    colorSecondaryLogo: "rgba(173,62,240,255)",
  },
  components: {
    // Layout: {
    //   bodyBg: "#001529",
    //   footerBg: "#001529",
    //   headerBg: "#001529",
    //   footerBorderTopColor: "rgba(255, 255, 255, 0.20)",
    //   contentBg: "#001529",
    // },
    // Modal: {
    //   contentBg: "#001529",
    //   headerBg: "#001529",
    // },
    // Typography: {
    //   mainColorText: "#FFF",
    //   colorPrimaryText: "#61BC18",
    // },
    // Button: {
    //   algorithm: true, // Enable algorithm
    //   colorPrimary: "#61BC18",
    //   colorLink: "#61BC18",
    //   defaultBg: "transparent",
    //   defaultColor: "#61BC18",
    //   defaultActiveBorderColor: "#61BC18",
    //   defaultActiveBg: "transparent",
    //   defaultHoverBg: "transparent",
    //   colorBgContainerDisabled: "#21273E", // Dimmed white background
    //   colorTextDisabled: "rgba(255, 255, 255, 0.60)",
    // },
    // Select: {
    //   colorText: "#8a9198",
    //   optionSelectedBg: "#001523",
    //   multipleItemBg: "#001C33",
    // },
    // icons: {
    //   whiteIcon: "#FFF",
    //   bgColorIcon: "#001529",
    //   colorPrimaryIcon: "#61BC18",
    // },
    // Drawer: {
    //   colorBgDrawer: "#001529",
    // },
    // Card: {
    //   colorBgCard: "#001C33",
    // },
    // Segmented: {
    //   trackBg: "#001C33",
    //   itemSelectedColor: "#FFF",
    //   itemSelectedBg: "#61BC18",
    //   itemColor: "#FFF",
    //   itemHoverColor: "#FFF",
    //   itemHoverBg: "rgba(255, 255, 255, 0.05)",
    // },
    // Dropdown: {
    //   colorText: "#8a9198",
    // },
    // Table: {
    //   headerBg: "#001C33",
    //   headerSortHoverBg: "#001C33",
    //   headerSortActiveBg: "#001C33",
    //   headerFilterHoverBg: "#001C33",
    //   headerSplitColor: "transparent",
    //   borderColor: "#001529",
    //   rowHoverBg: "#001523",
    //   colorBgContainer: "#001C33",
    //   stickyScrollBarBg: "#001523", // scroll bar bg
    // },
    // Notification: {
    //   colorBgElevated: "#001C33", //backgroundColor of notification popup
    // },
    // Slider: {
    //   railBg: "#001C33",
    //   railHoverBg: "#001C33",
    //   trackBgDisabled: "#bbe38a",
    // },
    // List: {
    //   lisItemBg: "#001C33",
    // },
  },
});

export const invertTheme = Object.freeze({
  token: {
    fontFamily: `-apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji'`,
    colorPrimary: "#61BC18",
    colorSecondaryLogo: "rgba(173,62,240,255)",
    colorLink: "#61BC18",
    colorBgContainer: "#001529",
    colorTextPlaceholder: "rgba(255, 255, 255, 0.60)",
    colorText: "rgba(255, 255, 255, 0.60)",
    colorTextQuaternary: "rgba(255, 255, 255, 0.60)",
    colorTextTertiary: "#8a9198",
    colorBgElevated: "#001C33", // color background of select popoup
    colorFillTertiary: "#001523", // color of hovered option
    // boxShadowSecondary:"0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)",//box shadow color of selcts popoup
    controlOutline: "transparent",
    colorErrorBg: "transparent",
    colorErrorBgHover: "transparent",
    colorErrorBorderHover: "#ff4d4f",
    product_default_image_bg: "#ffffff",
  },
  components: {
    Layout: {
      bodyBg: "#001529",
      footerBg: "#001529",
      headerBg: "#001529",
      footerBorderTopColor: "rgba(255, 255, 255, 0.20)",
      contentBg: "#001529",
    },
    Modal: {
      contentBg: "#001529",
      headerBg: "#001529",
    },
    Typography: {
      mainColorText: "#FFF",
      colorPrimaryText: "#61BC18",
    },
    Button: {
      algorithm: true, // Enable algorithm
      colorPrimary: "#61BC18",
      colorLink: "#61BC18",
      defaultBg: "transparent",
      defaultColor: "#61BC18",
      defaultActiveBorderColor: "#61BC18",
      defaultActiveBg: "transparent",
      defaultHoverBg: "transparent",
      colorBgContainerDisabled: "#21273E", // Dimmed white background
      colorTextDisabled: "rgba(255, 255, 255, 0.60)",
    },
    Select: {
      colorText: "#8a9198",
      optionSelectedBg: "#001523",
      multipleItemBg: "#001C33",
    },
    icons: {
      whiteIcon: "#FFF",
      bgColorIcon: "#001529",
      colorPrimaryIcon: "#61BC18",
    },
    Drawer: {
      colorBgDrawer: "#001529",
    },
    Card: {
      colorBgCard: "#001C33",
      cardTicketGradient: "linear-gradient(to bottom, #001C33 45%, #003366 100%)",
    },
    Segmented: {
      trackBg: "#001C33",
      itemSelectedColor: "#FFF",
      itemSelectedBg: "#61BC18",
      itemColor: "#FFF",
      itemHoverColor: "#FFF",
      itemHoverBg: "rgba(255, 255, 255, 0.05)",
    },
    Dropdown: {
      colorText: "#8a9198",
    },
    Table: {
      headerBg: "#001C33",
      headerSortHoverBg: "#001C33",
      headerSortActiveBg: "#001C33",
      headerFilterHoverBg: "#001C33",
      headerSplitColor: "transparent",
      borderColor: "#001529",
      rowHoverBg: "#001523",
      colorBgContainer: "#001C33",
      stickyScrollBarBg: "#001523", // scroll bar bg
    },
    Notification: {
      colorBgElevated: "#001C33", //backgroundColor of notification popup
    },
    Slider: {
      railBg: "#001C33",
      railHoverBg: "#001C33",
      trackBgDisabled: "#bbe38a",
    },
    List: {
      lisItemBg: "#001C33",
    },
  },
});
