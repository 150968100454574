import React, { useEffect, useState } from "react";
import "./CreateOrder.scss";
import { Divider, Typography, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import CashierCard from "../CashierCart/CashierCard";
import CashierCheckout from "../CashierCheckout/CashierCheckout";
import { useDispatch, useSelector } from "react-redux";
import { calculateOrder } from "../../../../features/order/orderActions";
import PatientCard from "../PatientCard/PatientCard";
import { DeskNumber } from "../../../../modals";
import LocalStorageService from "../../../../common/services/LocalStorageService";

export default function CreateOrder({
  token,
  isMobile,
  openNotificationWithIcon,
}) {
  const { items } = useSelector((state) => state?.card);
  const dispatch = useDispatch();
  const [orderCalculation, setOrderCalculation] = useState({});
  const [selectedPromoCode, setSelectedPromoCode] = useState(undefined);
  const [order_patient, set_order_patient] = useState(null);
  const [is_desk_number_modal_opened, set_is_desk_number_modal_opened] =
    useState(false);

  useEffect(() => {
    if (items?.length) {
      dispatch(
        calculateOrder({
          order_items: items?.map((item) => {
            return {
              variant_id: item?.variant?.id,
              quantity: item?.quantity,
            };
          }),
          promo_code: selectedPromoCode ? selectedPromoCode?.value : undefined,
        })
      ).then((data) => {
        if (data?.type === "calculateOrder/fulfilled") {
          if (data?.payload) {
            setOrderCalculation(data?.payload);
          }
        }
      });
    } else {
      setOrderCalculation({});
    }
  }, [items, dispatch, selectedPromoCode]);

  return (
    <div className="createOrder-cont">
      <div className="createOrder-cont__subTitileWrapper">
        <Typography.Text
          className="createOrder-cont__text"
          style={{
            color: token?.Typography?.mainColorText,
          }}>
          Payment Desk:
          <Typography.Text
            className="createOrder-cont__text"
            style={{
              color: token?.colorPrimary,
              display: "inline-block",
              marginLeft: "4px",
            }}>
            {LocalStorageService?.get("has_cashier_entered_desk_number")
              ? LocalStorageService?.get("has_cashier_entered_desk_number")
              : "-"}
          </Typography.Text>
        </Typography.Text>
        <Button
          size="small"
          title="Edit Payment Desk"
          icon={
            <EditOutlined
              style={{
                fontSize: "18px",
              }}
            />
          }
          onClick={() => {
            set_is_desk_number_modal_opened(true);
          }}
        />
      </div>
      <Divider
        style={{
          borderColor: token?.Layout?.footerBorderTopColor,
        }}
        className="createOrder-cont__divider"
        type="horizontal"
      />
      <div className="createOrder-cont__wrapper">
        <PatientCard
          token={token}
          set_order_patient={set_order_patient}
          order_patient={order_patient}
          openNotificationWithIcon={openNotificationWithIcon}
        />
      </div>
      <Divider
        style={{
          borderColor: token?.Layout?.footerBorderTopColor,
        }}
        className="createOrder-cont__divider"
        type="horizontal"
      />
      <div className="createOrder-cont__wrapper">
        <Typography.Text
          className="createOrder-cont__text"
          style={{
            color: token?.Typography?.mainColorText,
          }}>
          Cart
        </Typography.Text>
        <CashierCard token={token} />
        <CashierCheckout
          token={token}
          isMobile={isMobile}
          selectedPromoCode={selectedPromoCode}
          setSelectedPromoCode={setSelectedPromoCode}
          orderCalculation={orderCalculation}
          setOrderCalculation={setOrderCalculation}
          openNotificationWithIcon={openNotificationWithIcon}
          order_patient={order_patient}
          set_order_patient={set_order_patient}
        />
      </div>
      <DeskNumber
        token={token}
        title="Payment Desk"
        isOpened={is_desk_number_modal_opened}
        onCancel={() => {
          set_is_desk_number_modal_opened(false);
        }}
      />
    </div>
  );
}
