import "./ConfirmModal.scss";
import { Modal, Typography, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

export default function ConfirmModal({
  title,
  isOpened,
  subTitle,
  onOk,
  onCancel,
  icon,
  token,
  with_custom_footer = false,
  custom_btn_text,
  onCustomButtonClick,
}) {
  return (
    <Modal
      centered
      closeIcon={false}
      className="confirmModal-cont"
      onOk={() => {
        onOk();
      }}
      okText={"Yes"}
      onCancel={onCancel}
      destroyOnClose={true}
      title={
        <div className="confirmModal-cont__titleWrapper">
          {icon ? (
            icon
          ) : (
            <DeleteOutlined
              style={{
                color: `${token?.colorErrorText}`,
              }}
              className="confirmModal-cont__titleWrapper__icon"
            />
          )}
          <Typography.Text className="confirmModal-cont__titleWrapper__title">
            {title}
          </Typography.Text>
        </div>
      }
      cancelText="No"
      cancelButtonProps={{
        className: "confirmModal-cont__btn",
      }}
      okButtonProps={{
        type: "primary",
        className: "confirmModal-cont__btn",
      }}
      open={isOpened}
      footer={
        with_custom_footer
          ? (_, { OkBtn, CancelBtn }) => (
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    if (
                      typeof onCustomButtonClick === "function" &&
                      with_custom_footer
                    ) {
                      onCustomButtonClick();
                    }
                  }}>
                  {custom_btn_text ? custom_btn_text : ""}
                </Button>
                <CancelBtn />
                <OkBtn />
              </>
            )
          : undefined
      }>
      <Typography.Text className="confirmModal-cont__subTitle">
        {subTitle}
      </Typography.Text>
    </Modal>
  );
}
