import React, { useMemo } from "react";
import "./CustomerCard.scss";
import { theme, Button, Typography, Card, Image, Breadcrumb } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import RolesHelper from "../../common/services/permissions/RolesHelper";
import CustomEmpty from "../CustomEmpty/CustomEmpty";
import not_found_image from "../../assets/not_fount_image.png";
import Utils from "../../utils";

export default function CustomerCard({
  customer,
  onCloseClick,
  bodyStyle = {},
  descriptionStyle = {},
}) {
  const { useToken } = theme;
  const { token } = useToken();
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  const role = useMemo(() => {
    return isAuthenticated ? RolesHelper?.getRole() : {};
  }, [isAuthenticated]);

  return customer ? (
    <Card
      key={customer?.id}
      className="customerCard"
      style={{
        border: "none",
        backgroundColor: token?.Card?.colorBgCard,
      }}
      styles={{
        body: {
          width: "100%",
          display: "flex",
          gap: "12px",
          padding: "8px",
          ...bodyStyle,
        },
      }}>
      {!role?.is_kiosk && (
        <Image
          width="46px"
          height="56px"
          src={
            customer?.id_image_url ? customer?.id_image_url : not_found_image
          }
        />
      )}
      <div className="customerCard__descriptionCont" style={descriptionStyle}>
        <div className="customerCard__descriptionCont__deatilCont">
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: "4px",
            }}>
            <Typography.Text
              className="customerCard__descriptionCont__deatilCont__text"
              style={{
                color: token?.Typography?.mainColorText,
              }}>
              {`${customer?.first_name || ""} ${customer?.last_name || ""} ${
                role?.is_kiosk && customer?.email ? customer?.email : ""
              }`}
            </Typography.Text>
            {!role?.is_kiosk && (
              <Button
                title="Unhandle Patient"
                size="small"
                icon={
                  <CloseOutlined
                    style={{
                      fontSize: "18px",
                    }}
                  />
                }
                onClick={() => {
                  if (typeof onCloseClick === "function") {
                    onCloseClick();
                  }
                }}
              />
            )}
          </div>
          <div className="customerCard__descriptionCont__deatilCont__textWrapper">
            {!role?.is_kiosk && (
              <Typography.Text
                style={{
                  color: token?.Typography?.mainColorText,
                }}
                className="customerCard__descriptionCont__deatilCont__text"
                ellipsis={{
                  tooltip: {
                    title: `${customer?.email || ""}`,
                  },
                }}>
                {customer?.email || ""}
              </Typography.Text>
            )}
            <Breadcrumb
              separator="•"
              items={[
                {
                  title: customer?.phone_number ? (
                    <a href={`tel:${customer?.phone_number}`}>
                      <Typography.Text
                        style={{
                          color: token?.Typography?.mainColorText,
                        }}>
                        +1 {Utils?.format_phone_number(customer?.phone_number)}
                      </Typography.Text>{" "}
                    </a>
                  ) : (
                    <Typography.Text
                      style={{
                        color: token?.Typography?.mainColorText,
                      }}>
                      Phone
                    </Typography.Text>
                  ),
                },
                {
                  title: customer?.address ? (
                    <a
                      href={`${Utils?.getUrlInMap(customer?.address)}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      <Typography.Text
                        ellipsis={{
                          tooltip: {
                            title: `${customer?.address}`,
                          },
                        }}
                        style={{
                          whiteSpace: "normal", // Allow line breaks between words
                          overflowWrap: "break-word", // Ensure long words break properly
                          display: "inline", // Ensure the ellipsis works with the text
                          color: token?.Typography?.mainColorText,
                        }}>
                        {customer?.address}
                      </Typography.Text>
                    </a>
                  ) : (
                    <Typography.Text
                      style={{
                        color: token?.Typography?.mainColorText,
                      }}>
                      Address
                    </Typography.Text>
                  ),
                },
              ]?.filter((item) => item !== null)}
            />
          </div>
        </div>
      </div>
    </Card>
  ) : (
    <CustomEmpty description={"No patient"} width={"56"} height={"56"} />
  );
}
