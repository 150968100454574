import React, { useEffect, useMemo, useState } from "react";
import "./CartOrderDetails.scss";
import { Typography, Button } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { ShippingSelect } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  patchPatientShippingAddress,
  postPatientShippingAddress,
  getPatientDetails,
} from "../../../../features/patient/patientActions";
import { resetPatientError } from "../../../../features/patient/patientSLice";
import RolesHelper from "../../../../common/services/permissions/RolesHelper";

export default function ShippingAddress({
  token,
  openNotificationWithIcon,
  addressError,
  selectedShippingAddress,
  setSelectedShippingAddress,
  set_pick_up_point_opened,
}) {
  const { isAuthenticated, info } = useSelector((state) => state?.auth);
  const {
    patient,
    shipping_addresses: { results: shipping_addresses },
    loading,
    error,
  } = useSelector((state) => state?.patient);
  const dispatch = useDispatch();

  const role = useMemo(() => {
    return isAuthenticated ? RolesHelper?.getRole() : {};
  }, [isAuthenticated]);
  const [isOnEditMode, setIsOnEditMode] = useState(false);
  const [initialAddress, setInitialAddress] = useState(undefined);

  const addresses = [
    {
      id: "current",
      address1: patient?.address ? patient?.address : "",
      state: patient?.state ? patient?.state : "",
      city: patient?.city ? patient?.city : "",
      zipcode: patient?.zipcode ? patient?.zipcode : "",
      address2: patient?.address2 ? patient?.address2 : "",
    },
    ...(shipping_addresses || []),
  ];

  const updateShippingAddress = (params) => {
    if (params?.addressId !== "current") {
      dispatch(
        patchPatientShippingAddress({
          id: params?.id,
          addressId: params?.addressId,
          default: params.default,
        })
      ).then((res) => {
        if (res.type === "patch_patient_shiping_address/fulfilled") {
          setSelectedShippingAddress(initialAddress);
          dispatch(getPatientDetails({ id: params?.id }));
          setIsOnEditMode(false);
        } else if (res.type === "patch_patient_shiping_address/rejected") {
          const error = res?.payload;
          if (!Array.isArray(error) && typeof error === "object") {
            for (let key in error) {
              openNotificationWithIcon("error", key, error[key]);
              break;
            }
          } else {
            openNotificationWithIcon(
              "error",
              "Update Shipping address",
              "Shipping address did not changed"
            );
          }
        }
      });
    } else {
      setIsOnEditMode(false);
    }
  };

  useEffect(() => {
    setInitialAddress({
      id: "current",
      address1: patient?.address ? patient?.address : "",
      state: patient?.state ? patient?.state : "",
      city: patient?.city ? patient?.city : "",
      zipcode: patient?.zipcode ? patient?.zipcode : "",
      address2: patient?.address2 ? patient?.address2 : "",
    });
  }, [
    patient?.address,
    patient?.address2,
    patient?.state,
    patient?.city,
    patient?.zipcode,
  ]);

  return (
    <div className="cartOrderDetails-cont__shippingAddressCont">
      <div className="cartOrderDetails-cont__textWrapper">
        <Typography.Text
          className="cartOrderDetails-cont__text"
          style={{
            color: token?.Typography?.mainColorText,
          }}>
          Shipping Address
        </Typography.Text>
        {!role?.is_kiosk ? (
          isOnEditMode ? (
            <div style={{ display: "flex", gap: "8px" }}>
              <Button
                type="text"
                size="small"
                disabled={!initialAddress || loading || error}
                loading={loading}
                icon={
                  <CheckCircleOutlined
                    style={{
                      fontSize: "18px",
                      color: token?.icons?.colorPrimaryIcon,
                    }}
                  />
                }
                onClick={() => {
                  updateShippingAddress({
                    id: patient.id,
                    addressId: initialAddress?.id,
                    default: true,
                  });
                }}
              />
              <Button
                type="text"
                size="small"
                disabled={loading}
                icon={
                  <CloseCircleOutlined
                    style={{
                      fontSize: "18px",
                      color: token?.icons?.whiteIcon,
                    }}
                  />
                }
                onClick={() => {
                  if (error) {
                    dispatch(resetPatientError());
                  }
                  setIsOnEditMode(false);
                  if (selectedShippingAddress) {
                    setInitialAddress(selectedShippingAddress);
                  } else {
                    setInitialAddress({
                      id: "current",
                      address1: patient?.address ? patient?.address : "",
                      state: patient?.state ? patient?.state : "",
                      city: patient?.city ? patient?.city : "",
                      zipcode: patient?.zipcode ? patient?.zipcode : "",
                      address2: patient?.address2 ? patient?.address2 : "",
                    });
                  }
                }}
              />
            </div>
          ) : (
            <Button
              type="text"
              size="small"
              loading={loading}
              icon={
                patient?.address ? (
                  <EditOutlined
                    style={{
                      fontSize: "18px",
                      color: token?.icons?.colorPrimaryIcon,
                    }}
                  />
                ) : (
                  <PlusOutlined
                    style={{
                      fontSize: "18px",
                      color: token?.icons?.colorPrimaryIcon,
                    }}
                  />
                )
              }
              onClick={() => {
                if (patient?.address) {
                  if (error) {
                    dispatch(resetPatientError());
                  }
                  setIsOnEditMode(true);
                } else {
                  if (error) {
                    dispatch(resetPatientError());
                  }
                  set_pick_up_point_opened(true);
                }
              }}
            />
          )
        ) : null}
      </div>
      {!role?.is_kiosk ? (
        isOnEditMode ? (
          <ShippingSelect
            className="cartOrderDetails-cont__shippingAddressCont__shippingSelect"
            token={token}
            placeholder={"Shipping Address"}
            addBtnText="Add"
            loading={loading}
            options={addresses}
            selectedItem={initialAddress}
            setSelectedItem={setInitialAddress}
            addItem={(details) => {
              dispatch(
                postPatientShippingAddress({
                  ...details,
                  id: patient?.id,
                })
              ).then((res) => {
                if (res.type === "post_patient_shiping_address/rejected") {
                  let error = res?.payload;
                  for (let key in error) {
                    openNotificationWithIcon("error", key, error[key]);
                  }
                  setTimeout(() => {
                    dispatch(resetPatientError());
                  }, 2000);
                }
              });
            }}
            error={error}
            resetError={() => {
              dispatch(resetPatientError());
            }}
          />
        ) : addressError ? (
          <Typography.Text
            className="cartOrderDetails-cont__shippingAddressCont__text"
            style={{
              color: token?.colorError,
            }}>
            {addressError}
          </Typography.Text>
        ) : (
          <Typography.Text className="cartOrderDetails-cont__shippingAddressCont__text">
            {selectedShippingAddress
              ? `${
                  selectedShippingAddress?.address1
                    ? `${selectedShippingAddress?.address1},`
                    : ""
                } ${
                  selectedShippingAddress?.address2
                    ? `${selectedShippingAddress?.address2},`
                    : ""
                } ${
                  selectedShippingAddress?.city
                    ? `${selectedShippingAddress?.city},`
                    : ""
                } ${
                  selectedShippingAddress?.state
                    ? `${selectedShippingAddress?.state},`
                    : ""
                } ${
                  selectedShippingAddress?.zipcode
                    ? selectedShippingAddress?.zipcode
                    : ""
                }`
              : `${patient?.address ? `${patient?.address},` : ""}
      ${patient?.address2 ? `${patient?.address2},` : ""} 
      ${patient?.city ? `${patient?.city},` : ""}
      ${patient?.state ? `${patient?.state},` : ""}
      ${patient?.zipcode ? patient?.zipcode : ""}`}
          </Typography.Text>
        )
      ) : (
        <Typography.Text className="cartOrderDetails-cont__shippingAddressCont__text">
          {`${info.address ? `${info.address},` : ""}`}
        </Typography.Text>
      )}
    </div>
  );
}
