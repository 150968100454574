import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import "./Checkout.scss";
import { theme, Layout, Button, Typography, notification } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { BillingForm, OrderDetails, CartOrderDetails } from "./components";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useNavigate } from "react-router";
import { calculateOrder } from "../../features/order/orderActions";
import { useDispatch, useSelector } from "react-redux";
import RolesHelper from "../../common/services/permissions/RolesHelper";
import {
  resetPatientError,
  addPatient,
} from "../../features/patient/patientSLice";
import {
  getPatientDetails,
  createPatient,
  getPatientShippingAddresses,
  postPatient,
} from "../../features/patient/patientActions";
import Utils from "../../utils";

const { Content } = Layout;

export default function Checkout() {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback(
    (type, title, text) => {
      api[type]({
        message: title,
        description: text,
        placement: "top",
      });
    },
    [api]
  );
  const formRef = useRef(null);
  const hasRunRef = useRef(false);
  const { width, isMobile } = useWindowWidth();
  const {
    isAuthenticated,
    info: { patient: authenticatedPatient },
  } = useSelector((state) => state?.auth);
  const {
    error,
    unAuthenticatedPatientId,
    loading: patientDetailsLoading,
  } = useSelector((state) => state?.patient);
  const { items } = useSelector((state) => state?.card);
  const role = useMemo(() => {
    return isAuthenticated ? RolesHelper?.getRole() : {};
  }, [isAuthenticated]);
  const navigate = useNavigate();
  const [kiosk_patient, setKiosk_patient] = useState(null);
  const haveCartCredentials = isAuthenticated ? true : unAuthenticatedPatientId;
  const dispatch = useDispatch();
  const [orderCalculation, setOrderCalculation] = useState({});
  const [orderLoading, setOrderLoading] = useState(false);
  const [selectedPromoCode, setSelectedPromoCode] = useState(undefined);

  useEffect(() => {
    if (items?.length) {
      dispatch(
        calculateOrder({
          order_items: items?.map((item) => {
            return {
              variant_id: item?.variant?.id,
              quantity: item?.quantity,
            };
          }),
          promo_code: selectedPromoCode ? selectedPromoCode?.value : undefined,
        })
      ).then((data) => {
        if (data?.type === "calculateOrder/fulfilled") {
          if (data?.payload) {
            setOrderCalculation(data?.payload);
          }
        }
      });
    } else {
      setOrderCalculation({});
    }
  }, [JSON.stringify(items), dispatch, selectedPromoCode]);

  useEffect(() => {
    if (!isAuthenticated && haveCartCredentials && unAuthenticatedPatientId) {
      dispatch(getPatientDetails({ id: unAuthenticatedPatientId }));
      dispatch(getPatientShippingAddresses({ id: unAuthenticatedPatientId }));
    } else if (
      isAuthenticated &&
      haveCartCredentials &&
      authenticatedPatient?.id
    ) {
      if (!role?.is_kiosk) {
        dispatch(
          getPatientDetails({
            id: authenticatedPatient?.id ? authenticatedPatient?.id : "",
          })
        );
        dispatch(
          getPatientShippingAddresses({
            id: authenticatedPatient?.id ? authenticatedPatient?.id : "",
          })
        );
      }
    }
  }, [
    dispatch,
    isAuthenticated,
    haveCartCredentials,
    unAuthenticatedPatientId,
    authenticatedPatient?.id,
    role,
  ]);

  const addCartDetails = (params, reGenerateCaptcha) => {
    if (!isAuthenticated && !role?.is_kiosk) {
      dispatch(createPatient(params))?.then((res) => {
        if (res?.type === "create_patient/rejected") {
          let error = res?.payload;
          const keys = Object.keys(error); // Get all keys
          const lastIndex = keys.length - 1; // Find last index
          let currentIndex = 0;

          for (let key in error) {
            if (key === "non_field_errors") {
              if (error[key]) {
                if (error[key][0]?.includes("captcha")) {
                  formRef?.current?.setFields([
                    {
                      name: "captcha_value", // Field name you want to set the error for
                      errors: [error[key]], // Assuming your error has a 'message' field
                    },
                  ]);
                  if (currentIndex === lastIndex) {
                    Utils?.scrollIntoViewByClassName(".cartForm-cont");
                  }
                }
              }
            } else {
              formRef?.current?.setFields([
                {
                  name: key, // Field name you want to set the error for
                  errors: [error[key]], // Assuming your error has a 'message' field
                },
              ]);
              if (currentIndex === lastIndex) {
                Utils?.scrollIntoViewByClassName(".cartForm-cont");
              }
            }
            currentIndex++;
          }
          if (error?.detail && error && typeof error?.detail === "string") {
            openNotificationWithIcon("error", "Error", error?.detail);
            dispatch(resetPatientError());
          }
          if (error?.Error && error && typeof error?.Error === "string") {
            openNotificationWithIcon("error", "Error", error?.Error);
            dispatch(resetPatientError());
          }
          if (typeof reGenerateCaptcha === "function") {
            reGenerateCaptcha();
          }
        } else if (res?.type === "create_patient/fulfilled") {
          dispatch(
            addPatient({
              id: res?.payload?.id,
              phone_number: res?.payload?.phone_number,
            })
          );
          formRef?.current?.resetFields();
        }
      });
    } else {
      dispatch(postPatient(params))?.then((res) => {
        if (res?.type === "post_patient/rejected") {
          let error = res?.payload;
          for (let key in error) {
            formRef?.current?.setFields([
              {
                name: key, // Field name you want to set the error for
                errors: [error[key]], // Assuming your error has a 'message' field
              },
            ]);
          }
          if (error?.detail && error && typeof error?.detail === "string") {
            openNotificationWithIcon("error", "Error", error?.detail);
            dispatch(resetPatientError());
          }
          if (error?.Error && error && typeof error?.Error === "string") {
            openNotificationWithIcon("error", "Error", error?.Error);
            dispatch(resetPatientError());
          }
        } else if (res?.type === "post_patient/fulfilled") {
          if (role?.is_kiosk) {
            setKiosk_patient(res?.payload);
            formRef?.current?.resetFields();
          }
        }
      });
    }
  };

  useEffect(() => {
    if (hasRunRef.current) return;
    if (!haveCartCredentials) {
      if (error) {
        dispatch(resetPatientError());
      }
    }
    hasRunRef.current = true;
  }, [dispatch, error, haveCartCredentials]);

  return (
    <>
      {contextHolder}
      <Content
        className="checkout-cont"
        style={{
          backgroundColor: token?.Layout?.contentBg,
        }}>
        <div className="checkout-cont__header">
          <Button
            type="text"
            size="small"
            icon={
              <ArrowLeftOutlined
                style={{
                  fontSize: "24px",
                  color: token?.icons?.whiteIcon,
                }}
              />
            }
            onClick={() => {
              if (haveCartCredentials) {
                navigate(-1);
              } else {
                navigate(-1);
                formRef.current.resetFields();
                if (error) {
                  dispatch(resetPatientError());
                }
              }
            }}
          />
          <Typography.Text
            className="checkout-cont__header__title"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            {haveCartCredentials ? "Cart" : "Shipping Details"}
          </Typography.Text>
        </div>

        <div className="checkout-cont__prodDetails">
          <OrderDetails
            orderCalculation={orderCalculation}
            token={token}
            isMobile={isMobile}
            items={items}
            width={width}
            orderLoading={orderLoading}
          />
          <CartOrderDetails
            orderCalculation={orderCalculation}
            token={token}
            isMobile={isMobile}
            openNotificationWithIcon={openNotificationWithIcon}
            haveCartCredentials={haveCartCredentials}
            formRef={formRef}
            orderLoading={orderLoading}
            setOrderLoading={setOrderLoading}
            setSelectedPromoCode={setSelectedPromoCode}
            selectedPromoCode={selectedPromoCode}
            role={role}
            kiosk_patient={kiosk_patient}
            setKiosk_patient={setKiosk_patient}
            unAuthenticatedPatientId={unAuthenticatedPatientId}
          />
        </div>
        {!isAuthenticated && !unAuthenticatedPatientId ? (
          <BillingForm
            formRef={formRef}
            addCartDetails={addCartDetails}
            isMobile={isMobile}
            loading={patientDetailsLoading}
            error={error}
            dispatch={dispatch}
            resetPatientError={resetPatientError}
            role={role}
          />
        ) : isAuthenticated && role?.is_kiosk && !kiosk_patient ? (
          <BillingForm
            formRef={formRef}
            addCartDetails={addCartDetails}
            isMobile={isMobile}
            loading={patientDetailsLoading}
            error={error}
            dispatch={dispatch}
            resetPatientError={resetPatientError}
            role={role}
          />
        ) : null}
      </Content>
    </>
  );
}
